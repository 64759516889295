<template>
	<div class="pa-4">
		<login-card max-width="600" />
	</div>
</template>
<script>
export default {
	name: "LoginView",
	components: {LoginCard: () => import("@/components/LoginCard.vue")},
}
</script>
